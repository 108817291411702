import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BlogsNews from "../../Components/Home/BlogsNews";
import Brands from "../../Components/Home/Brands";
import Categories from "../../Components/Home/Categories";
import Footer from "../../Components/Home/Footer";
import Latest from "../../Components/Home/Latest";
import { NavbarDefault } from "../../Components/Home/Navbar";
import SearchBar from "../../Components/UI/SearchBar";
import MobileMenuBar from "../../Components/Home/MobileMenuBar";
import { getBanner } from "../../apis/Brands";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    try {
      async function fetch() {
        const response = await getBanner();
        setData(response.data.data);
      }
      fetch();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className=" relative min-h-screen overflow-hidden">
      <div className="w-screen shadow-lg">
        <NavbarDefault />
      </div>
      <div className=" w-screen mt-3">
        <div className="mx-3 lg:me-4">
          <Slider {...settings}>
            {data?.map((item) => (
              <div className="w-screen relative h-48 lg:h-96 ">
                <img
                  className="h-full object-cover w-full absolute cursor-pointer"
                  src={item?.image}
                  alt="banner"
                  onClick={() => navigate(`/warehouse/${item?.products?._id}`)}
                  // onClick={() =>
                  //   navigate(`/warehouse`, { state: item?.products?.item_name })
                  // }
                />
              </div>
            ))}
          </Slider>
        </div>

        <div className="lg:hidden mt-8">
          <SearchBar />
        </div>
        <div className="w-screen px-4 lg:px-12 py-4 lg:py-6 flex flex-col gap-4">
          <Categories />
          <Brands />
          <Latest />
        </div>
        <BlogsNews />
      </div>
      <MobileMenuBar />
      <Footer />
    </div>
  );
};

export default Home;
