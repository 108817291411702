import React from "react";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { TiSocialFacebook } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import styles from "./home.module.css";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className=" hidden lg:flex bg-[#013220] p-14 justify-between">
        <div className="text-white">
          <h1 className="text-2xl text-[#518270] font-medium mb-6">
            Quick Links
          </h1>
          <ul className=" text-base mt-3 flex gap-4 flex-col">
            <Link to={`/about`}>About</Link>
            <Link to={`/warehouse`}>Gefmart Warehouse</Link>
            <Link to="/categories">Categories</Link>
            <Link to="/brands">Top Brands</Link>
            {/* <Link to="login">Login/Register</Link> */}
            <Link to="/marketplace/add">Sell on Gefmart</Link>
            <li
              onClick={() => {
                Cookies.remove("gefmartUserToken");
                navigate("/");
              }}
            >
              Logout
            </li>
          </ul>
        </div>
        <div className=" max-w-sm">
          <div className="text-white">
            <h1 className="text-2xl text-[#518270] font-medium mb-6">
              Contact
            </h1>
            <ul className=" text-base mt-3 flex gap-4 flex-col justify-center">
              <li className="flex justify-center gap-3">
                <span className="mt-1">
                  <CiLocationOn />
                </span>{" "}
                <p>
                  Geften Electrical Solutions LLP 39/2475-B1, Suite 716, LR
                  Towers, SJRRA 104, South Janatha Road, Palarivattom PO, Kochi
                  Ernamkulam (Dist) Kerala-682025
                </p>
              </li>
              <li className="flex items-center gap-3">
                <span>
                  <MdOutlineMail />
                </span>
                <p>support@geften.com </p>{" "}
              </li>
              <div className="flex flex-col gap-1 bg-[#1F503E] w-72 justify-center p-5 rounded-lg">
                <a
                  href={`https://api.whatsapp.com/send?phone=+918921951010&text=Hello%20Gefmart`}
                  target="_blank"
                  rel="noreferrer"
                  className="flex gap-1 items-center"
                >
                  <FaWhatsapp className="text-3xl" /> <p>+91 892 195 1010</p>
                </a>
                <p className={styles.contactTime}>
                  9:00 am to 7:00 pm (Mon-Sat)
                </p>
              </div>
            </ul>
          </div>
        </div>
        <div>
          {" "}
          <div className="text-white">
            <h1 className="text-2xl text-[#518270] font-medium mb-6">
              Resources
            </h1>
            <ul className=" text-base mt-3 flex gap-4 flex-col">
              <Link to={"#"}>Terms and Conditions</Link>
              <Link to={`#`}>Privacy Policy</Link>
              <Link to={`#`}>Refund Policy</Link>
              <Link to={`blog`}>Blogs</Link>
              {/* <li>Visit our Website</li> */}
            </ul>
          </div>
        </div>
        <div>
          {" "}
          <div className="text-white">
            <h1 className="text-2xl text-[#518270] font-medium mb-6">
              Download our App
            </h1>
            <ul className=" text-base mt-3 flex gap-4 flex-col">
              <li>
                <img
                  className=" w-44 rounded-md"
                  src="/Screenshot 2024-03-06 210505.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  className=" w-44 rounded-md"
                  src="/Screenshot 2024-03-06 210534.png"
                  alt=""
                />
              </li>
              <h1 className="text-2xl text-[#518270] font-medium">
                Follow us on
              </h1>
              <li className="flex gap-3">
                <span>
                  <TiSocialFacebook className=" bg-blue-600 text-2xl rounded-full" />
                </span>
                <span>
                  <img
                    className=" w-6"
                    src="/toppng.com-ew-instagram-logo-transparent-related-keywords-logo-instagram-vector-2017-515x515.png"
                    alt=""
                  />
                </span>
                <span>
                  <img
                    className="w-6"
                    src="/toppng.com-twitter-x-logo-white-logo-icon-hd-png-3484x3468.png"
                    alt=""
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-[#1F503E] w-full text-center hidden lg:block">
        <p className=" text-[#628478] py-2">
          {" "}
          Copyright © 2024 Codescap. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
