import axios from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { IoMdCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineStorefront } from "react-icons/md";
import React, { useContext, useEffect, useState } from "react";

import { UserStates } from "../../context/UserContext";
import {
  getAllMarketPlaceListings,
  getMyMarketplacesUrl,
} from "../../apis/constants";
import { NavbarDefault } from "../../Components/Home/Navbar";
import GoBackAndSearch from "../../Components/GoBackAndSearch";
import styles from "./marketplace.module.css";
import PaginationBtns from "../../Components/paginationBtns";

const MarketPlacePage = () => {
  const accessToken = Cookies.get("gefmartUserToken");

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { currentLocation, userId, currentPage, setCurrentPage } =
    useContext(UserStates);

  const { id } = params;

  const [pageNo, setPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [allItems, setAllItems] = useState(true);
  const [totalPages, setTotalPages] = useState("");
  const [myTotalPages, setMyTotalPages] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [marketplaceListings, setMarketplaceListings] = useState([]);
  const [myMarketplaceListings, setMyMarketplaceListings] = useState([]);

  const getMarketPlaceItems = (page) => {
    const config = {
      method: "post",
      url: `${getAllMarketPlaceListings}/${page ? page : 1}`,
      data: {
        latitude: currentLocation.lat,
        longitude: currentLocation.lng,
        categoryId: id,
        search,
      },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setTotalPages(res.data.totalPages);
        setCurrentPage(Number(res.data.currentPage));
        // if (page === 1) {
        setMarketplaceListings(res.data.data);
        // } else {
        //   setMarketplaceListings([...marketplaceListings, ...res.data.data]);
        // }
      })
      .catch((err) => {
        setMarketplaceListings([]);
      });
  };

  const getMyMarketPlaceItems = (page) => {
    const config = {
      method: "post",
      url: getMyMarketplacesUrl,
      data: {
        userId,
        page,
      },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setMyTotalPages(res.data.totalPages);
        setMyMarketplaceListings(res.data.data);
        setCurrentPage(Number(res.data.currentPage));
        // setLoading(false);
      })
      .catch((err) => {
        setMarketplaceListings([]);
        // setLoading(false);
      });
  };

  const showMyListings = () => {
    getMyMarketPlaceItems(1);
    setAllItems(false);
  };

  const showAllListings = () => {
    getMarketPlaceItems(pageNo);
    setAllItems(true);
  };

  useEffect(() => {
    getMarketPlaceItems(currentPage);
  }, []);

  // search
  // useEffect(() => {
  //   // Function to check if the user has reached the bottom of the page
  //   const handleScroll = () => {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop >=
  //         document.documentElement.offsetHeight - 10 &&
  //       !loading &&
  //       totalPages !== pageNo
  //     ) {
  //       setPageNo((prevPageNo) => {
  //         setLoading(true);
  //         const nextPage = prevPageNo + 1;
  //         getMarketPlaceItems(nextPage);
  //         return nextPage;
  //       });
  //     }
  //   };

  //   // Add event listener for scrolling
  //   window.addEventListener("scroll", handleScroll);

  //   // Cleanup the event listener on component unmount
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [marketplaceListings, loading]);

  useEffect(() => {
    // const handler = setTimeout(() => {
    //   setDebouncedSearch(search);
    // }, 500);

    // return () => {
    //   clearTimeout(handler);
    // };

    getMarketPlaceItems(1);
  }, [search]);

  // useEffect(() => {
  //   if (debouncedSearch.length > 3 || search.length === 0) {
  //     getMarketPlaceItems(1);
  //   }
  // }, [debouncedSearch]);

  // useEffect(() => {
  //   if (location?.state?.length > 0) {
  //     setSearch(location.state);
  //   }
  // }, [location]);

  return (
    <div style={{ background: "#d3d3d391" }}>
      <NavbarDefault />
      <GoBackAndSearch
        title={"Marketplace"}
        placeholder={"marketplace"}
        setSearchKeyword={setSearch}
        searchKeyword={search}
      />

      {/* <GoBack title={"Marketplace"} />
      <Search placeholder={"marketplace"} setSearchKeyword={setSearch} /> */}

      <div style={{ background: "white" }}>
        <div className={styles.selectionContainer}>
          <div className={styles.sectionSelection}>
            <div className={styles.heading} onClick={showAllListings}>
              <p>Marketplace</p>
              <span
                className={styles.span}
                style={{
                  borderBottom: allItems ? "4px solid darkgreen" : "2px solid",
                }}
              />
            </div>
            <div className={styles.heading} onClick={showMyListings}>
              <p>My Listings</p>
              <span
                className={styles.span}
                style={{
                  borderBottom: !allItems ? "4px solid darkgreen" : "2px solid",
                }}
              />
            </div>
          </div>
          <div></div>
        </div>
        <div className={styles.parentContainer}>
          {!allItems && (
            <button className={styles.addBtn} onClick={() => navigate("add")}>
              Add Product to Marketplace
            </button>
          )}
          {marketplaceListings.length === 0 ? (
            <div
              style={{
                borderRadius: 5,
                marginBottom: "5px",
                textAlign: "center",
                padding: "100px 0",
              }}
            >
              No products
            </div>
          ) : (
            <>
              {(allItems ? marketplaceListings : myMarketplaceListings).map(
                (item) => (
                  <div
                    // className="!flex gap-2 overflow-x-scroll bg-white"
                    className={styles.item}
                    key={item._id} // Add a unique key if not present
                  >
                    <div
                      className="w-96 p-3 border shadow-md gap-2 rounded-md cursor-pointer"
                      onClick={() => navigate(`/marketplace/${item._id}`)}
                    >
                      <div className="flex gap-2">
                        <div className="w-4/12">
                          <img
                            src={item.images[0]}
                            alt="gefmart marketplace item"
                            className="w-44 object-cover"
                            style={{
                              width: "120px",
                              height: "120px",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                        <div>
                          <div className="flex w-full justify-between gap-4 mb-1">
                            <h1 className="text-[#013220] text-md mr-1">
                              {item.name}
                            </h1>
                          </div>
                          {item.address?.city !== "null" &&
                            item.address?.district !== "" && (
                              <div className="flex gap-1">
                                <p>
                                  <IoLocationOutline className="text-[#00BF63]" />
                                </p>
                                <p className="text-sm">{`${
                                  item.address?.city === "null"
                                    ? ""
                                    : `${item.address?.city},`
                                } ${item.address?.district}`}</p>
                              </div>
                            )}

                          {console.log("item", item)}

                          <div className="flex gap-1">
                            <MdOutlineStorefront className="text-[#00BF63]" />
                            <p className="text-sm">{item.user.business_name}</p>
                          </div>
                          {item.hidden_price ? (
                            <p className="text-sm font-medium text-[#00BF63] mt-2">
                              * contact for price
                            </p>
                          ) : (
                            <p className="text-lg font-medium text-[#00BF63] mt-2">
                              ₹{item?.retail_price || item?.wholesale_price}/pc
                            </p>
                          )}
                          <span className="text-sm text-slate-400">
                            {dayjs(item.createdAt).format(
                              "DD-MMM-YYYY hh:mm A"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="flex w-full justify-between mt-2">
                        <div className="flex items-center bg-[#013220] text-white px-9 rounded-md py-1 gap-2">
                          <a
                            href={`tel:+91${item.user.number}`}
                            className="contactBtn"
                          >
                            <IoMdCall />
                            <p style={{ marginLeft: "5px" }}>Call</p>
                          </a>
                        </div>
                        <div
                          className="flex items-center text-[#013220] border border-[#013220] bg-white rounded-md py-1 justify-center"
                          style={{ width: "60%" }}
                        >
                          <a
                            href={`https://api.whatsapp.com/send?phone=+91${item.user.number}&text=Hello,%20I%20am%20interested%20in%20buying%20your%20product%20${item.name}%20from%20Gefmart%20Marketplace`}
                            target="_blank"
                            rel="noreferrer"
                            className="contactBtn"
                          >
                            <FaWhatsapp />
                            <p style={{ marginLeft: "5px" }}>
                              {item.is_hidden ? "Request Price" : "Chat"}
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </div>

      <PaginationBtns
        totalPages={allItems ? totalPages : myTotalPages}
        getItems={allItems ? getMarketPlaceItems : getMyMarketPlaceItems}
        search={search}
      />
    </div>
  );
};

export default MarketPlacePage;
