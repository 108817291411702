import React, { useEffect, useState } from "react";
import { NavbarDefault } from "../../Components/Home/Navbar";
import HeadDescription from "../../Components/ProductDetail/HeadDescription";
import ProductDetails from "../../Components/ProductDetail/ProductDetails";
import Footer from "../../Components/Home/Footer";
import { getProductById } from "../../apis/products";
import { useParams } from "react-router";
import ProductContext from "../../utils/contexts/ProductContext";
import MobileMenuBarCart from "../../Components/ProductDetail/MenuBarCart";
import SimilarProducts from "../../Components/ProductDetail/SimilarProducts";
import BackIcon from "../../Components/BackIcon";

function ProductDetail() {
  const [data, setData] = useState();
  const params = useParams();
  useEffect(() => {
    async function fetch() {
      const response = await getProductById(params.id);
      setData(response?.data?.data);
    }
    fetch();
  }, []);

  return (
    <div className="md:px-10 px-3 +relative">
      <div className="w-screen shadow-lg">
        {/* <NavbarDefault /> */}
        <div className="flex gap-5 items-center md:py-7 py-3">
          <BackIcon />
          <h1 className="text-xl">Products</h1>
        </div>
      </div>
      <ProductContext.Provider value={data}>
        <HeadDescription />
        {/* <ProductDetails /> */}
        {/* <div className="p-2 mb-16 md:mb-2">
          <h1 className=" text-lg py-2">Latest</h1>
          <SimilarProducts />
        </div> */}

        <Footer />
        <MobileMenuBarCart id={data?._id} />
      </ProductContext.Provider>
    </div>
  );
}

export default ProductDetail;
