import React, { useContext } from "react";

import ProductContext from "../../utils/contexts/ProductContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AddToCart from "../AddToCart";

function HeadDescription() {
  const data = useContext(ProductContext);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="px-2 md:px-[70px] pt-2 md:pt-8 pb-2 md:pb-7 bg-[#E1E6E499] md:flex gap-10">
      <div className="md:w-1/2 rounded-[5px] md:px-5">
        <Slider {...settings}>
          <img
            src={data?.images[0]}
            className="md:w-full md:h-[420px] w-64 h-64"
          />
          <img
            src={data?.images[0]}
            className="md:w-full md:h-[420px] w-64 h-64"
          />
        </Slider>
      </div>

      <div className="md:w-1/2 md:pr-36 mt-10">
        <p className="text-[16px] poppins-medium text-[#252525]">
          {data?.name}
        </p>
        {/* <p className="text-[#777777] poppins-medium mt-3 leading-[24px]">
          {data?.description}
        </p> */}
        {/* <div className="flex mt-2 md:mt-4 items-center">
          <div className="flex">
            <IoStar className="text-[#F2DA5D] text-xl mx-1" />
            <IoStar className="text-[#F2DA5D] text-xl mx-1" />
            <IoStar className="text-[#F2DA5D] text-xl mx-1" />
            <IoStar className="text-[#F2DA5D] text-xl mx-1" />
            <IoStar className="text-[#F2DA5D] text-xl mx-1" />
          </div>
          <p className=" text-lg md:text-[20px] mx-3 poppins-medium text-[#777777]">
            5k rating
          </p>
        </div> */}
        <div className="w-full flex flex-col gap-2 mt-2">
          {/* <p className="text-[#252525] text-[16px] my-2 md:mt-6 poppins-medium">
          Select varient
        </p> */}
          {/* <Colorvarient
            data={{
              type: "Color",
              values: [
                <div className="flex gap-1">
                  <div className="w-5 h-5 bg-orange-900 rounded-full"></div>Brown
                </div>,
                <div className="flex gap-1">
                  <div className="w-5 h-5 bg-green-800 rounded-full"></div>Brown
                </div>,
              ],
            }}
          />
          <Colorvarient
            data={{ type: "Blades", values: ["Blade1", "Blade2", "Blade3"] }}
          /> */}

          <p>SKU No: {data?.sku}</p>
          <h1 className=" text-2xl md:text-4xl font-medium">
            Rate ₹{data?.rate}/pc
          </h1>
          <div></div>
          <div className="hidden md:block">
            {/* <AddToCartButton /> */}
            <AddToCart id={data?._id} justify={"space-around"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeadDescription;
