import Cookies from "js-cookie";

const baseUrl = "https://server.gefmart.com/api/";

const accessToken = Cookies.get("gefmartUserToken");

// category
const getAllCategoriesUrl = `${baseUrl}get-all-categories`;
const subCategoriesUrl = `${baseUrl}sub-categories-of-category/`;

// brand
const getAllBrandsUrl = `${baseUrl}get-all-brands`;

// blogs
const getAllBlogsUrl = `${baseUrl}get-all-blogs`;

// marketplace
const marketPlaceByCategoryUrl = `${baseUrl}get-all-marketplaces-categories`;
const getAllMarketPlaceListings = `${baseUrl}get-all-marketplaces`;
const getMyMarketplacesUrl = `${baseUrl}get-my-marketplaces`;
const getSingleMarketplaceUrl = `${baseUrl}marketplaces/`;

// user
const getUserDetailsUrl = `${baseUrl}users/`;

// products
const getProductsUrl = `${baseUrl}get-all-products/`;

export {
  baseUrl,
  marketPlaceByCategoryUrl,
  getAllMarketPlaceListings,
  getAllCategoriesUrl,
  getAllBrandsUrl,
  accessToken,
  getUserDetailsUrl,
  getAllBlogsUrl,
  getMyMarketplacesUrl,
  subCategoriesUrl,
  getSingleMarketplaceUrl,
  getProductsUrl,
};
