import React, { useContext, useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import PriceBar from "./PriceBar";
import { UserStates } from "../../context/UserContext";
import { baseUrl } from "../../apis/constants";
import axios from "axios";
import { message } from "antd";

const MyCart = () => {
  const { cart, getCart, accessToken, userId } = useContext(UserStates);

  const [totalSum, setTotalSum] = useState(0);

  const placeOrder = () => {
    const config = {
      method: "post",
      url: `${baseUrl}orders`,
      data: {
        userId,
        // addressId:"",
        products: cart.map((product) => ({
          productId: product.product._id,
          price: product.product.rate,
          total_quantity: product.quantity,
        })),
        date: new Date(),
      },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        getCart();
        message.success("Order placed successfully");
      })
      .catch((err) => err);
  };

  useEffect(() => {
    getCart();
  }, []);

  useEffect(() => {
    // Calculate total sum whenever cart changes
    let sum = 0;
    cart.forEach((item) => {
      if (item.quantity > 0) {
        const subtotal = item.product.rate * item.quantity;
        sum += subtotal;
      }
    });
    setTotalSum(sum);
  }, [cart]);

  return (
    <div className="flex flex-col gap-4 md:gap-0 md:flex-row  w-full justify-between">
      {cart.length > 0 ? (
        <>
          <div className="md:w-2/3">
            {cart.map((item) => {
              if (item.quantity > 0) {
                return <ProductCard product={item} />;
              }
            })}
          </div>
          <div className="md:w-1/3 flex flex-col items-center md:px-12">
            <PriceBar total={totalSum} />
            <div className="bg-white px-2 md:bg-transparent md:px-0 w-full text-center  mt-3 fixed md:static bottom-20">
              <div className="bg-[#013220] text-white py-2 rounded-lg">
                <p className="cursor-pointer" onClick={placeOrder}>
                  Place Order : ₹{totalSum}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <h4 style={{ textAlign: "center" }}>No items in the cart</h4>
      )}
    </div>
  );
};

export default MyCart;
