import React, { useContext, useEffect, useState } from "react";
import TimeLine from "./TimeLine";
import { UserStates } from "../../context/UserContext";
import { baseUrl } from "../../apis/constants";
import axios from "axios";

const MyOrders = () => {
  const { accessToken, userId } = useContext(UserStates);

  const [show, setShow] = useState();
  const [orders, setOrders] = useState();
  const [selectedIndex, setSelectedIndex] = useState();

  const getOrdersHistory = () => {
    const config = {
      method: "post",
      url: `${baseUrl}orders/${userId}`,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setOrders(res.data.data))
      .catch((err) => err);
  };

  useEffect(() => {
    getOrdersHistory();
  }, []);

  return (
    <div className="bg-[#F5F5F5]">
      {orders?.map((order, index) => (
        <>
          <div
            className="bg-white p-2 md:p-3 md:max-h-48"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              marginBottom: "10px",
              borderRadius: "6px",
            }}
          >
            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex gap-2">
                <div className="md:w-44 w-36">
                  <img
                    src={order?.product[0]?.productData?.images[0]}
                    alt=""
                    className=" object-cover"
                  />
                </div>
                <div className="w-3/4 lg:w-full">
                  <h1>{order?.product[0]?.productData?.name}</h1>
                  <p className=" text-gray-500 text-wrap">
                    {order?.product[0]?.productData?.description}
                  </p>
                </div>
              </div>
              {/* <div className="p-2 justify-center flex flex-col items-center md:w-1/2">
                <div className="md:text-xl font-medium mb-2 text-[#C5BE1C]">
                  Deliver on 03:40PM JAN 29
                </div>
              </div> */}
            </div>
            <div className="w-full text-end pe-5">
              <p
                className={`mb-2 ${
                  show && selectedIndex === index
                    ? `text-slate-400`
                    : `text-[#00BF63]`
                }`}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  setSelectedIndex(index);
                  if (show && selectedIndex === index) {
                    setShow(!show);
                  } else {
                    setShow(true);
                  }
                }}
              >
                {show && selectedIndex === index ? "Less" : "Show"}
              </p>
            </div>
          </div>

          {show && selectedIndex === index && (
            <div className="bg-[#E9ECEB] p-5">
              <TimeLine items={order?.statuses} />
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default MyOrders;
